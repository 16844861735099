import { Circle, Icon, keyframes, Link, Stack, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FiShoppingCart } from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";

import { selectUser } from "@/modules/auth/data/selectUser";
import { selectBasketProducts } from "@/modules/basket/data/selectors";
import { useAppSelector } from "@/modules/common";

export function ShoppingCart() {
  const products = useAppSelector(selectBasketProducts);
  const user = useAppSelector(selectUser);
  const totalCount = products.reduce(
    (total, currentItem) => total + currentItem.quantity,
    0
  );
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, [totalCount]);

  const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  40% { transform: scale(1.8) rotate(0); }
  100% { transform: scale(1) rotate(0); }
  `;

  const animation = `${animationKeyframes} 0.4s ease-in-out`;

  return (
    <Link
      as={RouterLink}
      to={user ? `/winkelwagen` : `/`}
      _hover={{ textDecoration: "none" }}
      data-cy="shoppingCartLink"
      data-testid="shoppingCartLink"
    >
      <Stack mr={1}>
        <Circle
          bg="none"
          size="40px"
          position="relative"
          color={"#5e5753"}
          opacity={user ? 1 : 0.4}
        >
          <Icon
            as={FiShoppingCart}
            w={{ base: 5, md: 6 }}
            h={{ base: 5, md: 6 }}
          />
          {!!totalCount && (
            <Circle
              as={motion.div}
              animation={animate ? animation : undefined}
              size="25px"
              border="2px solid white"
              bg="bsAccent"
              position="absolute"
              top={-1}
              right={-2}
              onAnimationEnd={() => setAnimate(false)}
            >
              <Text color="white" fontSize="2xs">
                {totalCount}
              </Text>
            </Circle>
          )}
        </Circle>
      </Stack>
    </Link>
  );
}
